<template>
  <section class="comments">
    <div class="new_comments">
      <p>أحدث التعليقات</p>
    </div>
    <LoadingSpinner v-if="!loading"/>
    <v-row class="comment_wrapper" v-for="item in comments" v-else>
      <v-col cols="3" sm="2" md="1" class="img">
        <v-img
            :lazy-src="`${env}${item.image}`"
            max-height="100"
            max-width="100"
            :src="`${env}${item.image}`"
            class="user_image"
        ></v-img>
      </v-col>
      <v-col cols="9" sm="10">
        <div class="head">
          <span class="time f-17 f_bold">{{ item.since }}</span>
          <span class="name f-17 f_bold">{{ item.name }}</span>
        </div>
        <div class="text ">
          {{ item.comment }}
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import {get_all_comments} from "@/api/admin";
import LoadingSpinner from "@/components/LoadingSpinner"
export default {
  components: {LoadingSpinner},
  data:() => ({
    comments: [],
    loading: true,
  }),
  mounted() {
    this.env = 'https://app.sabqnews.online/';
    get_all_comments()
    .then(res => {
      this.comments = res.comments.data;
      this.loading = true;
    }).catch(() => {})
  }
}
</script>